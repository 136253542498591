<template>
  <section class="user-guide-third-step">
    <image_upload
      :images="data.product_images || []"
      :title="data.product_title || 'product_image'"
      @updating="updated_images => $emit('update_value', updated_images, 'product_images')"
    />
  </section>
</template>

<script>
import image_upload from "./image_upload"

export default {
  components: {
    image_upload,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-guide-third-step {
    margin: $double-default-size 0;
  }
</style>
